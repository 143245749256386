import { graphql, useStaticQuery } from 'gatsby'
import { INewsItem } from '../types'

export const useArticlesData = (): INewsItem[] => {
  const images = useStaticQuery(imagesQuery)
  return [
    {
      id: '__BIM-ON-THE-CONSTRUCTION-SITE__',
      title: 'BIM on the construction site',
      to: '/bim-on-the-construction-site/',
      image:
        images.bim_on_the_construction_site.childImageSharp.gatsbyImageData,
    },
    {
      id: '__EVOLUTION-CONSTRUCTION-DOCUMENT-MANAGEMENT__',
      title:
        'Introducing the evolution in construction management: SIGNAX DOCS',
      to: '/evolution-construction-document-management/',
      image:
        images.evolution_construction_document_management.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__BIM-IMPLEMENTATION-IN-THE-COMPANY__',
      title: 'BIM Implementation in the Company',
      to: '/bim-implementation-in-the-company/',
      image:
        images.bim_implementation_in_the_company.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__WHAT-IS-BIM__',
      title: 'What is BIM',
      to: '/what-is-bim/',
      image: images.what_is_bim.childImageSharp.gatsbyImageData,
    },
    {
      id: '__COMMERCIAL-CONSTRUCTION-SOFTWARE__',
      title: 'Commercial Construction Software',
      to: '/commercial-construction-software/',
      image:
        images.commercial_construction_software.childImageSharp.gatsbyImageData,
    },
    {
      id: '__CAD-SOFTWARE-FOR-CONSTRUCTION__',
      title: 'CAD Software for Construction',
      to: '/cad-software-for-construction/',
      image:
        images.cad_software_for_construction.childImageSharp.gatsbyImageData,
    },
    {
      id: '__RESIDENTIAL-CONSTRUCTION-SOFTWARE__',
      title: 'Residential Construction Management Software',
      to: '/residential-construction-software/',
      image:
        images.residential_construction_software.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__VIRTUAL-DESIGN-AND-CONSTRUCTION__',
      title: 'Virtual Design and Construction (VDC)',
      to: '/virtual-design-and-construction/',
      image:
        images.virtual_design_and_construction.childImageSharp.gatsbyImageData,
    },
    {
      id: '__BIM-CONSTRUCTION-SOFTWARE__',
      title: 'BIM Construction Software',
      to: '/bim-construction-software/',
      image: images.bim_construction_software.childImageSharp.gatsbyImageData,
    },
    {
      id: '__CONSTRUCTION-BIDDING-SOFTWARE__',
      title: 'Construction Bidding Software',
      to: '/construction-bidding-software/',
      image:
        images.construction_bidding_software.childImageSharp.gatsbyImageData,
    },
    {
      id: '__CONSTRUCTION-ESTIMATING-SOFTWARE__',
      title: 'Construction Estimating Software',
      to: '/construction-estimating-software/',
      image:
        images.construction_estimating_software.childImageSharp.gatsbyImageData,
    },
    {
      id: '__CONSTRUCTION-SCHEDULE-MANAGEMENT__',
      title: 'Construction Schedule Management',
      to: '/construction-schedule-management/',
      image:
        images.construction_schedule_management.childImageSharp.gatsbyImageData,
    },
    {
      id: '__CONSTRUCTION-DAILY-REPORT-SOFTWARE__',
      title: 'Construction Daily Reports Software',
      to: '/construction-daily-reports-software/',
      image:
        images.construction_daily_reports_software.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__CONSTRUCTION-MANAGEMENT-SOFTWARE__',
      title: 'Construction Management Software',
      to: '/construction-management-software/',
      image:
        images.construction_management_software.childImageSharp.gatsbyImageData,
    },
    {
      id: '__ERP-SOFTWARE__',
      title: 'What is ERP Software, and Why Should I Use It?',
      to: '/erp-software/',
      image: images.erp_software.childImageSharp.gatsbyImageData,
    },
    {
      id: '__DOCUMENT-MANAGEMENT-SYSTEM__',
      title:
        'The most effective implementation of a document management system in your business',
      to: '/document-management-system/',
      image: images.document_management_system.childImageSharp.gatsbyImageData,
    },
    {
      id: '__BIM-CONSTRUCTION__',
      title: 'What is BIM (Building Information Modeling) in construction?',
      to: '/bim-construction/',
      image: images.bim_construction.childImageSharp.gatsbyImageData,
    },
    {
      id: '__WORKFORCE-PLANNING__',
      title: 'What is Workforce Planning?',
      to: '/workforce-planning/',
      image: images.workforce_planning.childImageSharp.gatsbyImageData,
    },
    {
      id: '__TAKEOFF-SOFTWARE-IN-CONSTRUCTION__',
      title:
        'Everything You Need To Know About Quantity Takeoff Software in Construction',
      to: '/takeoff-software-in-construction/',
      image:
        images.takeoff_software_in_construction.childImageSharp.gatsbyImageData,
    },
    {
      id: '__ELECTRONIC-SIGNATURE-AND-DIGITAL-SIGNATURE__',
      title: 'Difference between electronic signature and digital signature',
      to: '/electronic-signature-and-digital-signature/',
      image:
        images.electronic_signature_and_digital_signature.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__CONSTRUCTION-PRODUCTIVITY__',
      title: 'Improving and calculating construction productivity',
      to: '/construction-productivity/',
      image: images.construction_productivity.childImageSharp.gatsbyImageData,
    },
    {
      id: '__SUCCESSFUL-WORKFORCE-PLANNING__',
      title: 'The main steps of successful workforce planning',
      to: '/successful-workforce-planning/',
      image:
        images.successful_workforce_planning.childImageSharp.gatsbyImageData,
    },
    {
      id: '__CONSTRUCTION-ESTIMATING-SOFTWARE-MEANING__',
      title: 'What is construction estimating software?',
      to: '/construction-estimating-software-meaning/',
      image:
        images.construction_estimating_software_meaning.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__ELECTRONIC-DIGITAL-SIGNATURE__',
      title:
        'The high cost of building materials - what to do and how to save money?',
      to: '/electronic-digital-signature/',
      image:
        images.electronic_digital_signature.childImageSharp.gatsbyImageData,
    },
    {
      id: '__TENDERING-PROCESS__',
      title: 'How long does the tendering process take?',
      to: '/tendering-process/',
      image: images.tendering_process.childImageSharp.gatsbyImageData,
    },
    {
      id: '__INTEGRATED-CONSTRUCTION-MANAGEMENT__',
      title: 'What is integrated construction management?',
      to: '/integrated-construction-management/',
      image:
        images.integrated_construction_management.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__REDUCE-RISK-INTEGRATED-CONSTRUCTION-MANAGEMENT__',
      title: 'How to reduce risks through integrated construction management?',
      to: '/reduce-risk-integrated-construction-management/',
      image:
        images.reduce_risk_integrated_construction_management.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__CONSTRUCTION-BIDDING__',
      title: 'The main principles of construction bidding',
      to: '/construction-bidding/',
      image: images.construction_bidding.childImageSharp.gatsbyImageData,
    },
    {
      id: '__SAFETY-CONSTRUCTION-BUSINESS__',
      title: 'Strategies to improve the safety of the construction business',
      to: '/safety-construction-business/',
      image:
        images.safety_construction_business.childImageSharp.gatsbyImageData,
    },
    {
      id: '__TOP-3-METHODS-TO-CONTROL-PROJECT-COSTS__',
      title: 'Top 3 methods to control project costs',
      to: '/top-3-methods-to-control-project-costs/',
      image:
        images.top_3_methods_to_control_project_costs.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__EMPLOYEES-IN-CONSTRUCTION__',
      title: "Employees' rights & responsibilities in construction",
      to: '/employees-in-construction/',
      image: images.employees_in_construction.childImageSharp.gatsbyImageData,
    },
    {
      id: '__RENEWABLE-ENERGY-IN-CONSTRUCTION-INDUSTRY__',
      title: 'Renewable energy in the construction industry',
      to: '/renewable-energy-in-construction-industry/',
      image:
        images.renewable_energy_in_construction_industry.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__SOURCES-RENEWABLE-ENERGY-FOR-CONSTRUCTION__',
      title: 'Top 3 sources of renewable energy for construction sites',
      to: '/sources-renewable-energy-for-construction/',
      image:
        images.sources_renewable_energy_for_construction.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__TOP-3-DANGEROUS-JOBS-IN-CONSTRUCTION__',
      title: 'Top 3 Dangerous Jobs in Construction',
      to: '/top-3-dangerous-jobs-in-construction/',
      image:
        images.top_3_dangerous_jobs_in_construction.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__WORKFORCE-PLANNING-IN-CONSTRUCTION__',
      title: 'Why Is Workforce Planning In Construction Important?',
      to: '/workforce-planning-in-construction/',
      image:
        images.workforce_planning_in_construction.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__MODULAR-HOMES-IN-THE-UAE__',
      title: 'Modular homes in the UAE - pricing and builders',
      to: '/modular-homes-in-the-UAE/',
      image: images.modular_homes_in_the_UAE.childImageSharp.gatsbyImageData,
    },
    {
      id: '__TECHNOLOGY-IN-CONSTRUCTION__',
      title: 'How do technology and data help in construction?',
      to: '/technology-in-construction/',
      image: images.technology_in_construction.childImageSharp.gatsbyImageData,
    },
    {
      id: '__SELF-REPAIRING-CONSTRUCTION-MATERIALS__',
      title: 'Self-Repairing Construction Materials - What Are They?',
      to: '/self-repairing-construction-materials/',
      image:
        images.self_repairing_construction_materials.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__CAREER-IN-CONSTRUCTION__',
      title: 'Best Tips for Building a Successful Construction Career',
      to: '/career-in-construction/',
      image: images.career_in_construction.childImageSharp.gatsbyImageData,
    },
    {
      id: '__SIMPLE-CONSTRUCTION-SITE-SAFETY-RULES__',
      title: 'Simple construction site safety rules',
      to: '/simple-construction-site-safety-rules/',
      image:
        images.simple_construction_site_safety_rules.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__COMPETITIVE-BIDDING-ON-YOUR-PROJECTS__',
      title: 'Why Do You Need Competitive Bidding On Your Projects?',
      to: '/competitive-bidding-on-your-projects/',
      image:
        images.competitive_bidding_on_your_projects.childImageSharp
          .gatsbyImageData,
    },
    {
      id: '__BEST-CONSTRUCTION-PROJECT-MANAGEMENT-STRATEGIES__',
      title:
        'The Best Strategies for Successful Construction Project Management',
      to: '/best-construction-project-management-strategies/',
      image:
        images.best_construction_project_management_strategies.childImageSharp
          .gatsbyImageData,
    },
  ]
}

const imagesQuery = graphql`
  query {
    bim_on_the_construction_site: file(
      relativePath: { eq: "articles/bim-on-the-construction-site/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    evolution_construction_document_management: file(
      relativePath: {
        eq: "articles/evolution-construction-document-management/preview.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    bim_implementation_in_the_company: file(
      relativePath: {
        eq: "articles/bim-implementation-in-the-company/preview.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    what_is_bim: file(
      relativePath: { eq: "articles/what-is-bim/preview.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    commercial_construction_software: file(
      relativePath: {
        eq: "articles/commercial-construction-software/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    cad_software_for_construction: file(
      relativePath: { eq: "articles/cad-software-for-construction/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    residential_construction_software: file(
      relativePath: {
        eq: "articles/residential-construction-software/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    virtual_design_and_construction: file(
      relativePath: {
        eq: "articles/virtual-design-and-construction/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    bim_construction_software: file(
      relativePath: { eq: "articles/bim-construction-software/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    construction_bidding_software: file(
      relativePath: { eq: "articles/construction-bidding-software/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    construction_schedule_management: file(
      relativePath: {
        eq: "articles/construction-schedule-management/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    construction_estimating_software: file(
      relativePath: {
        eq: "articles/construction-estimating-software/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    construction_daily_reports_software: file(
      relativePath: {
        eq: "articles/construction-daily-reports-software/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    construction_management_software: file(
      relativePath: {
        eq: "articles/construction-management-software/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    erp_software: file(
      relativePath: { eq: "articles/erp-software/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    document_management_system: file(
      relativePath: { eq: "articles/document-management-system/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    bim_construction: file(
      relativePath: { eq: "articles/bim-construction/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    workforce_planning: file(
      relativePath: { eq: "articles/workforce-planning/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    takeoff_software_in_construction: file(
      relativePath: {
        eq: "articles/takeoff-software-in-construction/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    electronic_signature_and_digital_signature: file(
      relativePath: {
        eq: "articles/electronic-signature-and-digital-signature/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    construction_productivity: file(
      relativePath: { eq: "articles/construction-productivity/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    successful_workforce_planning: file(
      relativePath: { eq: "articles/successful-workforce-planning/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    construction_estimating_software_meaning: file(
      relativePath: {
        eq: "articles/construction-estimating-software-meaning/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    electronic_digital_signature: file(
      relativePath: { eq: "articles/electronic-digital-signature/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    tendering_process: file(
      relativePath: { eq: "articles/tendering-process/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    integrated_construction_management: file(
      relativePath: {
        eq: "articles/integrated-construction-management/preview.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    reduce_risk_integrated_construction_management: file(
      relativePath: {
        eq: "articles/reduce-risk-integrated-construction-management/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    construction_bidding: file(
      relativePath: { eq: "articles/construction-bidding/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    safety_construction_business: file(
      relativePath: { eq: "articles/safety-construction-business/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    top_3_methods_to_control_project_costs: file(
      relativePath: {
        eq: "articles/top-3-methods-to-control-project-costs/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    employees_in_construction: file(
      relativePath: { eq: "articles/employees-in-construction/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    renewable_energy_in_construction_industry: file(
      relativePath: {
        eq: "articles/renewable-energy-in-construction-industry/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    sources_renewable_energy_for_construction: file(
      relativePath: {
        eq: "articles/sources-renewable-energy-for-construction/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    top_3_dangerous_jobs_in_construction: file(
      relativePath: {
        eq: "articles/top-3-dangerous-jobs-in-construction/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    workforce_planning_in_construction: file(
      relativePath: {
        eq: "articles/workforce-planning-in-construction/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    modular_homes_in_the_UAE: file(
      relativePath: { eq: "articles/modular-homes-in-the-UAE/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    technology_in_construction: file(
      relativePath: { eq: "articles/technology-in-construction/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    self_repairing_construction_materials: file(
      relativePath: {
        eq: "articles/self-repairing-construction-materials/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    career_in_construction: file(
      relativePath: { eq: "articles/career-in-construction/preview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    simple_construction_site_safety_rules: file(
      relativePath: {
        eq: "articles/simple-construction-site-safety-rules/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    competitive_bidding_on_your_projects: file(
      relativePath: {
        eq: "articles/competitive-bidding-on-your-projects/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
    best_construction_project_management_strategies: file(
      relativePath: {
        eq: "articles/best-construction-project-management-strategies/preview.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 358, placeholder: BLURRED)
      }
    }
  }
`
